import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ChakraProvider, extendTheme, Center, Spinner } from "@chakra-ui/react";
import PublicLayout from "components/Layout";
import './index.css';

import "react-toastify/dist/ReactToastify.css";

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        fontWeight: 400,
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
  },
  colors: {
    brand: {
      100: "#eeeefb",
      200: "#ebeafa",
      300: "#c2c1f1",
      400: "#9997e8",
      500: "#706dde",
      600: "#4744d5",
      700: "#332fd0",
      800: "#2e2abb",
      900: "#2926a6",
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
  },
});

const App = () => {
  return (
    <Suspense
      fallback={
        <Center h={window.innerHeight}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.900" size="xl" />
        </Center>
      }
    >
      <ChakraProvider theme={theme}>
        <Routes>
          {publicRoutes.map(({ path, Component, Layout, childrenRoute }) => {
            return childrenRoute ? (
              <Route path={path}>
                {childrenRoute.map((childRoutes) => {
                  const ChildLayout = childRoutes.Layout;
                  const ChildComponent = childRoutes.Component;

                  return (
                    <Route
                      path={childRoutes.path}
                      element={
                        <ChildLayout>
                          <ChildComponent {...(childRoutes?.props || {})} />
                        </ChildLayout>
                      }
                    />
                  );
                })}
              </Route>
            ) : (
              <Route
                path={path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            );
          })}
        </Routes>
      </ChakraProvider>
    </Suspense>
  );
};

const publicRoutes = [
  {
    title: "Home",
    path: "/",
    Component: lazy(() => import("pages/Home")),
    Layout: PublicLayout,
  },
  {
    title: "Polling",
    path: "/polling",
    childrenRoute: [
      {
        path: "",
        Component: lazy(() => import("pages/Polling")),
        Layout: PublicLayout,
      },
      {
        path: ":pollId",
        Component: lazy(() => import("pages/PollingDetails")),
        Layout: PublicLayout,
        props: {
          proposalType: "polling",
        },
      },
    ],
  },
  {
    title: "Executive",
    path: "/executive",
    childrenRoute: [
      {
        path: "",
        Component: lazy(() => import("pages/Executive")),
        Layout: PublicLayout,
      },
      {
        path: ":pollId",
        Component: lazy(() => import("pages/PollingDetails")),
        Layout: PublicLayout,
        props: {
          proposalType: "executive",
        },
      },
    ],
  },
  {
    title: "Create Proposal",
    path: "/create-proposal",
    Layout: PublicLayout,
    Component: lazy(() => import("pages/CreateProposal")),
  },
];

export default App;
