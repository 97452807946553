import { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { Box, Flex, Text, Stack } from "@chakra-ui/layout";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from "@chakra-ui/modal";
import { FiMenu, FiX } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails, resetUserDetails, userStateSelector } from "store/userSlice";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { getCardsList } from "utils/voting";
import { getChainType, getGovernorContractJson } from "utils/contractController";
import bscIcon from "assets/binance-logo.svg";
import metamaskIcon from "assets/metamask.svg";
import walletconnectIcon from "assets/walletconnect.svg";
import BasketCoinLogo from "assets/basketcoinlogo.png";
import BasketCoinRoundLogo from "assets/basketcoinroundlogo.png";
import WrongChainError from "components/WrongChainError";

const NAV_ITEMS = [
  {
    label: "Polling",
    href: "/polling",
  },
  {
    label: "Executive",
    href: "/executive",
  },
  {
    label: "Create proposal",
    href: "/create-proposal",
  },
];

export default function WithSubnavigation() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isSelected, setIsSelected] = useState("/");
  const { isOpen: isLoginModalOpen, onClose: onLoginModalClose, onOpen: onLoginModalOpen } = useDisclosure();
  const dispatch = useDispatch();
  const { account, chainType } = useSelector(userStateSelector);

  const changeIsSelected = (selected) => setIsSelected(selected);
  const navbarBackgroundColor = useColorModeValue("brand.200", "gray.800");

  useEffect(() => {
    const currentProvider = localStorage.getItem("currentProvider");
    if (currentProvider && currentProvider === "Metamask"  && window?.ethereum?.isMetaMask) {
      connectMetamask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account === "" && !localStorage.getItem("currentProvider") && window?.ethereum?.isMetaMask) {
      onLoginModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const connectMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.requestAccounts();
        const userAddress = accounts[0];
        const chainId = `0x${Number(await web3.eth.getChainId()).toString(16)}`;
        let cardsList = [];

        localStorage.setItem("currentProvider", "Metamask");
        window.web3 = web3;
        window.provider = window.ethereum;
        const chainTp = getChainType(chainId);
        if (chainTp) {
          getCardsList(chainTp).then((cardsList) => {
            dispatch(
              setUserDetails({
                cardsList,
              })
            );
          });
        }
        const governorJson = getGovernorContractJson(chainTp ?? 'Mainnet');
        const governorContract = new web3.eth.Contract(governorJson.abi, governorJson.address);
        governorContract.methods
          .isAdmin(userAddress)
          .call()
          .then((isAdmin) => {
            dispatch(
              setUserDetails({
                isAdmin,
              })
            );
          });

        dispatch(
          setUserDetails({
            account: `${userAddress}`,
            chainId,
            cardsList,
            chainType: chainTp,
          })
        );

        // Subscribe to accounts change
        window.ethereum.on("accountsChanged", async (accounts) => {
          if (chainType) cardsList = await getCardsList(chainType);
          const isAdmin = await governorContract.methods.isAdmin(accounts[0]).call();

          dispatch(
            setUserDetails({
              account: `${accounts[0]}`,
              cardsList,
              isAdmin,
            })
          );
        });

        // Subscribe to chainId change
        window.ethereum.on("chainChanged", (chainId) => {
          web3.eth.getAccounts(async (error, accounts) => {
            if (error) {
              console.log(error);
            } else {
              const chainTp = getChainType(chainId);
              if (chainTp) cardsList = await getCardsList(chainTp);
              const isAdmin = await governorContract.methods.isAdmin(accounts[0]).call();

              dispatch(
                setUserDetails({
                  account: `${accounts[0]}`,
                  chainId,
                  chainType: chainTp,
                  cardsList,
                  isAdmin,
                })
              );
            }
          });
        });

        // Subscribe to session disconnection
        window.ethereum.on("disconnect", (code, reason) => {
          dispatch(resetUserDetails());
          localStorage.removeItem("currentProvider");
        });
        onLoginModalClose();
      } catch (error) {
        console.log(error);
        onLoginModalClose();
      }
    } else {
      toast.error("Please install metamask");
      onLoginModalClose();
    }
  };

  const connectWalletConnect = async () => {
    try {
      //  Create WalletConnect Provider
      const provider = new WalletConnectProvider({
        infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      });
      //  Enable session (triggers QR Code modal)
      await provider.enable();
      const web3 = new Web3(provider);
      const accounts = await web3.eth.requestAccounts();
      const userAddress = accounts[0];
      const chainId = `0x${Number(await web3.eth.getChainId()).toString(16)}`;
      let cardsList = [];
      const chainTp = getChainType(chainId);
      if (chainTp) cardsList = await getCardsList(chainTp);
      const governorJson = getGovernorContractJson(chainTp);
      const governorContract = new web3.eth.Contract(governorJson.abi, governorJson.address);

      localStorage.setItem("currentProvider", "walletConnect");

      window.web3 = web3;
      window.provider = provider;
      const isAdmin = await governorContract.methods.isAdmin(userAddress).call();

      dispatch(
        setUserDetails({
          account: `${userAddress}`,
          chainId,
          cardsList,
          chainType: chainTp,
          isAdmin,
        })
      );

      // Subscribe to accounts change
      provider.on("accountsChanged", async (accounts) => {
        if (chainType) cardsList = await getCardsList(chainType);
        const isAdmin = await governorContract.methods.isAdmin(accounts[0]).call();

        dispatch(
          setUserDetails({
            account: `${accounts[0]}`,
            cardsList,
            isAdmin,
          })
        );
      });

      // Subscribe to chainId change
      provider.on("chainChanged", async (chainId) => {
        const chainTp = getChainType(chainId);
        if (chainTp) cardsList = await getCardsList(chainTp);
        dispatch(
          setUserDetails({
            chainId,
            chainType: chainTp,
            cardsList,
          })
        );
      });

      // Subscribe to session disconnection
      provider.on("disconnect", (code, reason) => {
        dispatch(resetUserDetails());
        localStorage.removeItem("currentProvider");
      });
      onLoginModalClose();
    } catch (error) {
      console.log(error);
      onLoginModalClose();
    }
  };

  return (
    <Box>
      <ToastContainer pauseOnHover={false} />
      <WrongChainError />
      <WalletOptions
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
        connectMetamask={connectMetamask}
        connectWalletConnect={connectWalletConnect}
      />
      <Flex
        bg={isSelected === "/" ? navbarBackgroundColor : "#f7f8f9"}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 6 }}
        px={{ base: 8 }}
        align={"center"}
      >
        <Flex flex={{ base: 0.3, lg: "auto" }} ml={{ base: -2 }} display={{ base: "flex", lg: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <FiX w={3} h={3} /> : <FiMenu w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1.5 }} justify={{ base: "center" }}>
          <NavLink to={"/"} onClick={() => changeIsSelected("/")}>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
            >
              <Image className="lglogo" src={BasketCoinLogo} h={"10"} />
              <Image className="smlogo" src={BasketCoinRoundLogo} h={"12"} />
            </Text>
          </NavLink>

          <Flex display={{ base: "none", lg: "flex" }} ml={"auto"} mr={"auto"}>
            <DesktopNav changeIsSelected={changeIsSelected} isSelected={isSelected} />
          </Flex>
        </Flex>

        <Stack flex={{ base: 1, md: 0 }} justify={"flex-end"} direction={"row"} spacing={6}>
          {chainType ? <Button
            display={{ base: "none", md: "inline-flex" }}
            leftIcon={<Image src={bscIcon} h={"4"} />}
            rounded={"full"}
            variant={"outline"}
            fontSize={"sm"}
            bg={"white"}
            border={"1px solid white"}
            px={"6"}
            _hover={{
              bg: "white",
              borderColor: "brand.800",
            }}
          >
            {chainType}
          </Button> : null}
          <Button
            fontSize={"sm"}
            rounded={"full"}
            variant={"outline"}
            bg={"white"}
            border={"1px solid white"}
            px={"8"}
            leftIcon={
              account === "" ? (
                <></>
              ) : (
                <Image src={`https://avatars.dicebear.com/api/identicon/${account}.svg`} h={"4"} />
              )
            }
            _hover={{
              bg: "white",
              borderColor: "brand.800",
            }}
            onClick={() => (account === "" ? onLoginModalOpen() : () => {})}
          >
            {account !== "" ? `${account.slice(0, 6)}...${account.slice(-4)}` : `Connect wallet`}
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ changeIsSelected, isSelected }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("brand.800", "white");
  const selectedColor = useColorModeValue("brand.700", "white");

  return (
    <Stack direction={"row"} spacing={6}>
      {NAV_ITEMS.map((navItem) => (
        <Flex key={navItem.label} align={"center"}>
          <NavLink
            to={navItem.href ?? "/"}
            style={({ isActive }) => {
              if (isActive) changeIsSelected(navItem.href);
            }}
          >
            <Text
              p={2}
              fontSize={"sm"}
              fontWeight={400}
              color={isSelected === navItem.href ? selectedColor : linkColor}
              _hover={
                isSelected === navItem.href
                  ? { textDecoration: "none" }
                  : {
                      textDecoration: "none",
                      color: linkHoverColor,
                    }
              }
            >
              {navItem.label}
            </Text>
          </NavLink>
        </Flex>
      ))}
    </Stack>
  );
};

const MobileNav = ({ onClose }) => {
  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ lg: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} onClose={onClose} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, onClose }) => {
  return (
    <Stack spacing={4}>
      <NavLink to={href}>
        <Flex
          py={2}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          onClick={onClose}
        >
          <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
            {label}
          </Text>
        </Flex>
      </NavLink>
    </Stack>
  );
};

const WalletOptions = ({ isOpen, onClose, connectMetamask, connectWalletConnect }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a wallet</ModalHeader>
          <ModalBody>
            <Stack spacing={"4"} my={4}>
              <Button
                leftIcon={<Image src={metamaskIcon} h={"6"} />}
                variant={"outline"}
                justifyContent={"left"}
                fontSize={"sm"}
                size={"lg"}
                onClick={() => connectMetamask()}
              >
                Metamask
              </Button>
              <Button
                leftIcon={<Image src={walletconnectIcon} h={"6"} />}
                variant={"outline"}
                justifyContent={"left"}
                fontSize={"sm"}
                size={"lg"}
                onClick={() => connectWalletConnect()}
              >
                WalletConnect
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
