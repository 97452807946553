import React from "react";
import { VStack, Stack, Text } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { Image, Link } from "@chakra-ui/react";
import { FaTelegramPlane, FaTwitter, FaMediumM, FaFacebookF, FaLinkedinIn, FaGithub, FaYoutube } from "react-icons/fa";

import footerLogo from "assets/footer-logo.png";

const socials = [
  {
    Icon: FaTelegramPlane,
    link: "https://t.me/bskt_global",
    name: "Telegram",
  },
  {
    Icon: FaTwitter,
    link: "https://twitter.com/Basketcoin_BSKT",
    name: "Twitter",
  },
  {
    Icon: FaMediumM,
    link: "https://basketcoin.medium.com/",
    name: "Medium",
  },
  {
    Icon: FaFacebookF,
    link: "https://fb.me/bsktglobal",
    name: "Facebook",
  },
  {
    Icon: FaLinkedinIn,
    link: "https://www.linkedin.com/company/basketcoin",
    name: "Linkedin",
  },
  {
    Icon: FaGithub,
    link: "https://github.com/BasketCoinBSKT",
    name: "Github",
  },
  {
    Icon: FaYoutube,
    link: "https://www.youtube.com/c/BasketCoinOfficial",
    name: "Youtube",
  },
];

const SocialIcons = () => {
  return (
    <Stack direction={"row"}>
      {socials.map(({ Icon, link, name }) => (
        <Link href={link} target={"_blank"}>
          <IconButton key={name} icon={<Icon />} _hover={{ bg: "brand.600", color: "white" }} />
        </Link>
      ))}
    </Stack>
  );
};

const Footer = () => {
  return (
    <VStack style={{ position: 'relative' }} borderTop={"1px solid"} borderColor={"gray.300"} pt={8} pb={8} mt={"72px"} spacing={"6"}>
      <div className="contract">
        <p>Governance contract address: </p>
        <a href="https://bscscan.com/address/0xe6c504095cd67a5d23a887904b9b573c0fe650e5" target='_blank'>0xE6c504095cD67a5d23A887904b9B573c0Fe650e5</a>
      </div>
      <SocialIcons />
      <Image src={footerLogo} h={"20"} p={"3"} />
      <Text fontSize={{ lg: "sm", base: "xs" }} textAlign={"center"}>
        © {new Date().getFullYear()} BasketCoin. All rights reserved
      </Text>
    </VStack>
  );
};

export default Footer;
