import { configureStore } from "@reduxjs/toolkit";

import { userSlice } from "./userSlice";
import { proposalSlice } from "./proposalSlice";

const store = configureStore({
  reducer: {
    userState: userSlice.reducer,
    proposalState: proposalSlice.reducer,
  },
});

export default store;
