import governorJson from "contracts/governor.json";
import basketCoinNftJsonTestnet from "contracts/basketCoinNFT.json";

const getChainType = (chainId) => {
  const CHAIN_MAP = {
    "0x61": "Testnet",
    "0x38": "Mainnet",
  };
  return CHAIN_MAP[chainId];
};

const getGovernorContractJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: governorJson.address,
      abi: governorJson.abi,
    };
  }
};

const getBasketCoinNftJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: basketCoinNftJsonTestnet.address,
      abi: basketCoinNftJsonTestnet.abi,
    };
  }
};

export { getGovernorContractJson, getBasketCoinNftJson, getChainType };
