import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pollingData: {},
  executiveData: {},
};

export const proposalSlice = createSlice({
  name: "proposalState",
  initialState,
  reducers: {
    addPollingDetails: (state, { payload }) => ({
      ...state,
      pollingData: { ...state.pollingData, ...payload },
    }),
    addExecutiveDetails: (state, { payload }) => ({
      ...state,
      executiveData: { ...state.executiveData, ...payload },
    }),
    resetPollingDetails: (state) => Object.assign(state, initialState),
  },
});

export const proposalStateSelector = (state) => state.proposalState;

export const { addPollingDetails, addExecutiveDetails, updatePollingRef, updateExecutiveRef, resetPollingDetails } =
  proposalSlice.actions;
