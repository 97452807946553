import { useEffect, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import { useSelector } from "react-redux";
import { userStateSelector } from "store/userSlice";

const WrongChainError = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const network = window?.ethereum?.networkVersion;
  const { account } = useSelector(userStateSelector);

  const switchToTestnet = async () => {
    const ethereum = window.provider;
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x61" }],
      });
      onClose();
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61",
                chainName: "BscTestnet",
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
              },
            ],
          });
          onClose();
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  };

  const switchToMainnet = async () => {
    const ethereum = window.provider;
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      });
      onClose();
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName: "BSC Mainnet",
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
              },
            ],
          });
          onClose();
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  };

  useEffect(() => {
    if (network !== '56' && account.length > 1) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, account]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent style={{ margin: '25vh 15vw' }}>
          <ModalHeader textAlign={"center"} color={"red.400"}>
            Wrong chain selected!
          </ModalHeader>
          <ModalBody pb={6}>
            <div className="chainbtns" spacing={8}>
              <Button
                w={"100%"}
                variant={"outline"}
                borderColor={"brand.700"}
                fontSize={"sm"}
                color={"gray.700"}
                _hover={{ bg: "brand.700", color: "white", cursor: "pointer" }}
                onClick={switchToMainnet}
              >
                Switch to BSC mainnet
              </Button>
              <Button
                w={"100%"}
                variant={"outline"}
                borderColor={"brand.700"}
                fontSize={"sm"}
                color={"gray.700"}
                _hover={{ bg: "brand.700", color: "white", cursor: "pointer" }}
                onClick={switchToTestnet}
              >
                Switch to BSC testnet
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WrongChainError;
