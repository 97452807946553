import Web3 from "web3";
import axios from "axios";

import { getBasketCoinNftJson } from "utils/contractController";

const getCardsList = async (chainType) => {
  const BasketCoinNFTJson = getBasketCoinNftJson(chainType);
  const web3 = new Web3(window?.provider);
  const BasketCoinNFTContract = new web3.eth.Contract(BasketCoinNFTJson.abi, BasketCoinNFTJson.address);
  const accounts = await web3.eth.requestAccounts();
  const userAddress = accounts[0];
  const nftBalance = await BasketCoinNFTContract.methods.balanceOf(userAddress).call();
  const cardsList = [];
  for (let i = 0; i < nftBalance; i++) {
    const token = await BasketCoinNFTContract.methods.tokenOfOwnerByIndex(userAddress, i).call();
    const tokenURI = await BasketCoinNFTContract.methods.tokenURI(token).call();
    const { data } = await axios.get(tokenURI);
    if (data?.cardType) cardsList.push(data.cardType);
  }
  return cardsList;
};

const getPairName = (cardsList) => {
  let cards = "";
  let suits = "";
  let handResult = "No Hands Found";
  let result = true;

  cardsList.forEach((item) => {
    var splits = item.split("");
    if (splits.length === 2) {
      cards += splits[0];
      suits += splits[1];
    } else if (splits.length === 1) {
      cards += splits[0];
    }
  });

  const format = cards + "#" + suits;

  const hands = [
    { regex: /(AKQJ10)#(.)\2{4}.*/g, name: "royalFlush" }, // 0
    { regex: /(2345A|23456|34567|45678|56789|6789T|789JT|89JQT|9JKQT|AJKQT)#(.)\2{4}.*/g, name: "straightFlush" }, // 1
    { regex: /(.)\1{3}.*#.*/g, name: "fourOfAKind" }, // 2
    { regex: /((.)\2{2}(.)\3{1}#.*|(.)\4{1}(.)\5{2}#.*)/g, name: "fullHouse" }, // 3
    { regex: /.*#(.)\1{4}.*/g, name: "flush" }, // 4
    { regex: /(2345A|23456|34567|45678|56789|6789T|789JT|89JQT|9JKQT|AJKQT)#.*/g, name: "straight" }, // 5
    { regex: /(.)\1{2}.*#.*/g, name: "threeOfAKind" }, // 6
    { regex: /(.)\1{1}.*(.)\2{1}.*#.*/g, name: "twoPair" }, // 7
    { regex: /(.)\1{1}.*#.*/g, name: "onePair" }, // 8
  ];

  hands.forEach((match) => {
    if (match.regex.test(format) && result) {
      handResult = match.name;
      result = false;
    }
  });
  return handResult;
};

const getCards = (cardsList) => {
  let cards = [];
  cardsList.forEach((item) => {
    var splits = item.split("");
    cards.push(splits[0]);
  });
  return cards;
};

export { getCardsList, getPairName, getCards };
